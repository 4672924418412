import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
import { downTemplate } from '@/utils/download';

/**
 * 设备分页查询
 * @param params
 */
export function findDevicePage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/page',
    method: 'get',
    params,
  });
}

/**
 * 设备分页查询：产品Key自己传，参数名称productKey，值逗号分隔
 * @param params
 */
export function findDevicePage1(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/list1',
    method: 'get',
    params,
  });
}

/**
 * 设备分页查询
 * @param params
 */
export function findByDNOrDescription(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/findByDNOrDescription',
    method: 'get',
    params,
  });
}

/**
 * 设备分页查询
 * @param params
 */
export function exportDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/export',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备名称
 * @param params
 */
export function updateDeviceName(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/description',
    method: 'post',
    data: params,
  });
}
/**
 * 更新设备编码
 * @param params
 */
export function updateDeviceCode(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/deviceCode',
    method: 'post',
    data: params,
  });
}
/**
 * 设备列表查询
 * @param params
 */
export function findDeviceList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/list',
    method: 'get',
    params,
  });
}

/**
 * 创建设备
 * @param params
 */
export function createDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/create',
    method: 'post',
    data: params,
  });
}

/**
 * 删除设备
 * @param params
 */
export function deleteDeviceById(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/delete/' + params.deviceId,
    method: 'delete',
  });
}

/**
 * 批量删除设备
 * @param params
 */
export function batchDeleteDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/delete/batch',
    method: 'put',
    data: params,
  });
}

/**
 * 批量启用设备
 * @param params
 */
export function batchEnableDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/enable/batch',
    method: 'put',
    data: params,
  });
}

export function batchReportDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/report/batch',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备
 * @param params
 */
export function updateDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备启用状态
 * @param params
 */
export function updateEnableStatus(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/enableStatus',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备本地日志上报状态
 * @param params
 */
export function updateLocalLogReport(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/localLogReport',
    method: 'post',
    data: params,
  });
}

/**
 * 查询设备详情
 * @param params
 */
export function findDeviceDetail(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/detail',
    method: 'get',
    params,
  });
}

export function findDeviceApps(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/app/list',
    method: 'get',
    params,
  });
}

export function findDeviceLiveApps(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/app/liveList',
    method: 'get',
    params,
  });
}

/**
 * 查询设备证书
 * @param params
 */
export function viewCert(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/cert',
    method: 'get',
    params,
  });
}

/**
 * 查看MQTT连接参数
 * @param params
 */
export function findMqttParam(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/findMqttParam',
    method: 'get',
    params,
  });
}

/**
 * 查询属性历史数据(分页)
 * @param params
 */
export function findPropertyHistoryPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/page',
    method: 'get',
    params,
  });
}

/**
 * 查询属性历史数据(分页) - doris
 * @param params
 */
export function findPropertyHistoryPageDoris(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/page',
    method: 'get',
    params,
  });
}

/**
 * 查询属性数据(分页) - doris
 * @param params
 */
export function findPropertyPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/propPage',
    method: 'get',
    params,
  });
}

/**
 * 查询事件上报历史数据(分页)
 * @param params
 */
export function findEventHistoryPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/event/page',
    method: 'get',
    params,
  });
}

/**
 * 查询事件上报历史数据(分页) - doris
 * @param params
 */
export function findEventHistoryPageDoris(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/event/page',
    method: 'get',
    params,
  });
}

/**
 * 查询服务调用历史数据(分页)
 * @param params
 */
export function findServiceHistoryPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/service/page',
    method: 'get',
    params,
  });
}

/**
 * 查询设备影子
 * @param params
 */
export function findDeviceShadow(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/shadow',
    method: 'get',
    params,
  });
}

/**
 * 获取网关下的所有协议通道
 */
export function findChannelList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/channel/list',
    method: 'get',
    params,
  });
}

/**
 * 查询产品tsl
 * @param params
 */
export function findTsl(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/tsl',
    method: 'get',
    params,
  });
}

/**
 * 调用服务
 * @param params
 */
export function callDeviceService(params) {
  return request({
    url: envInfo.bgApp.setPath + '/device/callDeviceService',
    method: 'post',
    data: params,
  });
}
/**
 * 批量设备迁移
 * @param params
 */
export function batchMoveDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/move/batch',
    method: 'post',
    data: params,
  });
}
/**
 * 设备迁移
 * @param params
 */
export function moveDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/move',
    method: 'post',
    data: params,
  });
}

/**
 * 通道分页查询
 * @param params
 */
export function findChannelPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/channel/page',
    method: 'get',
    params,
  });
}

/**
 * 创建通道
 * @param params
 */
export function createChannel(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/channel/create',
    method: 'post',
    data: params,
  });
}

/**
 * 更新通道配置
 * @param params
 */
export function updateChannel(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/channel/update',
    method: 'post',
    data: params,
  });
}

/**
 * 删除通道
 * @param params
 */
export function deleteChannelById(params) {
  return request({
    url:
      envInfo.bgApp.thingsPath + '/device/channel/delete/' + params.channelId,
    method: 'delete',
  });
}

/**
 * 根据deviceId和tagName删除标签
 * @param params
 */
export function deleteTagByTagNameAndDeviceId(deviceId, tagName) {
  return request({
    url:
      envInfo.bgApp.thingsPath +
      '/device/tagDelete/' +
      deviceId +
      '/' +
      tagName,
    method: 'delete',
  });
}

/**
 * 根据deviceId和tagName添加标签
 * @param params
 */
export function addTagByTagNameAndDeviceId(deviceId, tagName) {
  return request({
    url:
      envInfo.bgApp.thingsPath + '/device/tagAdd/' + deviceId + '/' + tagName,
    method: 'post',
  });
}

/**
 * 创建产品Ota
 * @param params
 */
export function createDeviceOta(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/ota',
    method: 'post',
    data: params,
  });
}

/**
 * 查询产品Ota分页
 * @param params
 */
export function findOtaPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/ota/page',
    method: 'get',
    params,
  });
}

/**
 * 设备升级
 * @param params
 */
export function deviceLevelUp(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/ota/deploy',
    method: 'post',
    data: params,
  });
}

/**
 * 查询设备升级记录
 * @param params
 */
export function findOtaTaskPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/ota/taskPage',
    method: 'get',
    params,
  });
}

/**
 * 查询设备升级记录日志
 * @param taskId
 */
export function findOtaTaskLogList(taskId) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/ota/taskLogList/' + taskId,
    method: 'get',
  });
}
/**
 * 设备部署
 * @param {*} params
 * @returns
 */
export function deployConfig(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/config/deploy',
    method: 'post',
    data: params,
  });
}

/**
 * 取消部署
 * @param {*} params
 * @returns
 */
export function cancelDeploy(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/config/cancel',
    method: 'post',
    data: params,
  });
}

/**
 * 查询部署日志动态分页
 * @param {*} params
 * @returns
 */
export function findDeployLogPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/config/page',
    method: 'get',
    params,
  });
}

/**
 * 查询部署日志动态列表
 * @param {*} params
 * @returns
 */
export function findDeployLogList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/config/dynamic',
    method: 'get',
    params,
  });
}

/**
 * 查询设备日志(分页)
 * @param params
 */
export function findThingsLogPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/log/page',
    method: 'get',
    params,
  });
}

/**
 * 查询属性设置历史(分页)
 * @param params
 */
export function findPropertyHistory(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/propertySet/page',
    method: 'get',
    params,
  });
}

//导出属性 - doris
export function exportProperty(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/export', //{userAccount:userAccount}
    method: 'post',
    data: params,
  });
}

/**
 * 查询设备上下线(分页)
 * @param params
 */
export function getDeviceOpeHistory(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/deviceStatus/page',
    method: 'get',
    params,
  });
}

/**
 * 创建设备分组
 * @param params
 */
export function createDeviceGroup(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/create/group',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备分组
 * @param params
 */
export function updateDeviceGroup(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/group',
    method: 'post',
    data: params,
  });
}

/**
 * 绑定设备
 * @param params
 */
export function bindDeviceGroup(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device//bind/device/group',
    method: 'post',
    data: params,
  });
}

/**
 * 查询设备分组树
 * @param params
 */
export function findDeviceGroup(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/search/deviceGroup',
    method: 'get',
    data: params,
  });
}

/**
 * 查询未绑定分组下的对应产品的设备列表前50条
 * @param params
 * @returns
 */
export function findNotExistsGroupDeviceList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/search/notExistsGroupDeviceList',
    method: 'get',
    params,
  });
}

/**
 * 查询绑定分组设备
 * @param params
 */
export function findDeviceGroupList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/search/bind/device',
    method: 'get',
    params,
  });
}

/**
 * 删除绑定设备
 * @param params
 */
export function deleteBindDevice(params) {
  return request({
    url:
      envInfo.bgApp.thingsPath +
      '/device/delete/bind/' +
      params.groupId +
      '/' +
      params.deviceId,
    method: 'delete',
  });
}

/**
 * 批量删除绑定设备
 * @param params
 */
export function batchBindDevice(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/batch/bindDevice',
    method: 'post',
    data: params,
  });
}

/**
 * 删除分组
 * @param params
 */
export function deleteGroup(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/delete/group/' + params.groupId,
    method: 'delete',
  });
}

/**
 * 分组树排序
 * @param params
 */
export function groupOrderOn(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/orderOn',
    method: 'post',
    data: params,
  });
}

/**
 * 下载设备模版
 */
export function downDeviceTemplate() {
  downTemplate('ruge.subDevice', envInfo.bgApp.thingsPath);
}

/**
 * 下载属性模版
 */
export function downPropertyTemplate() {
  downTemplate('ruge.property', envInfo.bgApp.dorisPath);
}

/**
 * 查询协议
 * @param params
 */
export function findProtocolPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/protocolPage',
    method: 'get',
    params,
  });
}

/**
 * 添加协议
 * @param params
 */
export function createProtocol(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/create/protocol',
    method: 'post',
    data: params,
  });
}

/**
 * 更新协议
 * @param params
 */
export function updateProtocol(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/protocol',
    method: 'post',
    data: params,
  });
}

/**
 * 删除协议
 * @param params
 */
export function deleteProtocolById(params) {
  return request({
    url:
      envInfo.bgApp.thingsPath + '/device/delete/protocol/' + params.protocolId,
    method: 'delete',
  });
}

/**
 * 批量增加标签
 * @param params
 */
export function batchTagAdd(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/batch/tagAdd',
    method: 'post',
    data: params,
  });
}

/**
 * 批量删除标签
 * @param params
 */
export function batchTagDelete(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/batch/tagDelete',
    method: 'post',
    data: params,
  });
}

/**
 * 查询所选设备标签
 * @param params
 */
export function findDeviceTageList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/search/deviceTageList',
    method: 'get',
    params,
  });
}

/**
 * 批量增加标签
 * @param params
 */
export function batchupdateEnableStatus(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/batch/updateEnableStatus',
    method: 'post',
    data: params,
  });
}

/**
 * 查询所有设备ID
 * @param params
 */
export function findDeviceIdList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/find/deviceIdList',
    method: 'get',
    params,
  });
}

/**
 * 更新隔离时间
 * @param params
 */
export function batchIsolateTime(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/isolateTime',
    method: 'post',
    data: params,
  });
}

/**
 * 取消隔离时间
 * @param params
 */
export function cancelIsolateTime(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/cancel/isolateTime',
    method: 'post',
    data: params,
  });
}

/**
 * 更新产品扩展信息
 * @param params
 */
export function updateDeviceExtendInfo(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/update/extendInfo',
    method: 'post',
    data: params,
  });
}

/**
 * 获取中继任务信息
 * @param params
 */
export function getRelayServiceInfo(params) {
  return request({
    url: envInfo.bgApp.repeaterPath + '/job/get',
    method: 'get',
    params,
  });
}

/**
 * 创建中继任务
 * @param params
 */
export function updateRelayServiceInfo(params) {
  return request({
    url: envInfo.bgApp.repeaterPath + '/job/create',
    method: 'post',
    data: params,
  });
}

/**
 * 查询属性历史最大最小平均值
 * @param params
 */
export function findPropertyHistoryAvg(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/statsRange',
    method: 'get',
    params,
  });
}

/**
 * 查询属性历史数据趋势图
 * @param params
 */
export function findPropertyHistoryTrend(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/property/statistics',
    method: 'get',
    params,
  });
}
